<template>
    <div class="footer-container">
        <p class="copyright-text">Copyright © {{year}} Superintendence of Cultural Heritage ® All Rights Reserved.</p>

        <div class="row-divider"></div>

        <div class="engineered-by-arkafort">
            <p>Engineered by</p>
            <img src="../assets/white-logo.svg" alt="Arkafort"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Footer",
        computed: {
            year() {
                return new Date().getFullYear();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .footer-container {
        @apply flex flex-col h-auto w-full bg-secondary py-6 mt-auto;
        grid-area: footer;

        @screen md {
            @apply flex flex-row items-center justify-between;
        }

        .copyright-text {
            @apply text-white text-xxs text-center mx-2;

            @screen md {
                @apply py-2 mb-0 border-b-0 pr-8 mr-8 border-r border-primary text-xs ml-auto;
            }

            @screen lg {
                @apply text-sm;
            }
        }

        .row-divider {
            @apply pb-4 mb-4 border-b border-primary w-8 mx-auto;

            @screen md {
                @apply hidden;
            }
        }

        .engineered-by-arkafort {
            @apply flex flex-row mx-auto;

            @screen md {
                @apply ml-0;
            }

            & > p {
                @apply text-xxs text-white;

                @screen lg {
                    @apply text-sm;
                }
            }

            & > img {
                @apply ml-4 h-4;
            }
        }
    }
</style>