<template>
    <loading-screen v-if="is_loading_forms"/>
    <div v-else class="page-container">
        <h1>{{$t('forms.start_the_process')}}</h1>

        <div class="forms-container">
            <div class="form-container" v-for="(template, index) in forms">
                <h2>{{index+1}}. {{template.attributes.name}}</h2>

                <router-link :to="{name: 'form', params: {id: template.id}}">
                    <font-awesome-icon :icon="['far', 'arrow-circle-right']"/>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingScreen from "../../components/LoadingScreen";

export default {
    name: "forms-index",
    components: {LoadingScreen},
    data() {
        return {
            forms: [],
            is_loading_forms: false
        }
    },
    methods: {
        getForms() {
            this.is_loading_forms = true;

            this.$axios.get('templates')
                .then(({data}) => {
                    this.forms = data.data;
                    this.is_loading_forms = false;
                })
                .catch(e => {
                    this.is_loading_forms = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('forms.error_retrieve')),
                        type: 'error',
                    });
                });
        }
    },
    mounted() {
        this.getForms();
    }
}
</script>

<style lang="scss" scoped>
    .page-container {
        @apply py-12 px-8 items-center;

        @screen md {
            @apply px-12;
        }

        @screen xl {
            @apply py-20;
        }

        h1 {
            @apply text-center font-bold text-3xl text-secondary mb-12;

            @screen md {
                @apply text-4xl mb-16;
            }

            @screen xl {
                @apply text-5xl;
            }
        }

        .forms-container {
            @apply grid grid-cols-1 gap-y-4 w-full;

            @screen md {
                @apply grid-cols-2 gap-8;
            }

            @screen xl {
                @apply grid-cols-3 gap-10 max-w-7xl;
            }

            .form-container {
                @apply bg-white rounded-2xl p-4 flex flex-col items-center;

                @screen md {
                    @apply p-6;
                }

                h2 {
                    @apply text-secondary text-center font-bold mb-4;
                }

                a {
                    @apply mt-auto;
                }

                svg {
                    @apply text-primary text-3xl cursor-pointer mt-auto;
                }
            }
        }
    }
</style>