<template>
    <div class="app-container">
        <Navbar v-if="$route.name !== 'login' && $route.name !== 'reset' && $route.name !== 'request_register' && $route.name !== 'register'"/>
        <div class="main-wrapper">
            <MobileMenu v-if="$store.state.menuExpanded"/>
            <main :class="{'mobile-menu-expanded': $store.state.menuExpanded}">
                <slot/>
            </main>
            <CookiesBar v-if="$store.getters.user && !$store.getters.cookies_accepted && !$store.getters.cookies_declined"/>
            <Footer/>
        </div>
    </div>
</template>

<script>
    import Navbar from "../components/Navbar";
    import Footer from "../components/Footer";
    import MobileMenu from "@/components/MobileMenu";
    import CookiesBar from "@/components/CookiesBar.vue";

    export default {
        name: "AppLayout",
        components: {CookiesBar, MobileMenu, Footer, Navbar}
    }
</script>

<style lang="scss" scoped>
    .app-container {
        @apply grid h-screen text-secondary;

        grid-template-columns: auto 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas: "menubar menubar" "sidebar content";

        .main-wrapper {
            @apply flex flex-col;
            grid-area: content;

            main {
                @apply flex-1;

                &.mobile-menu-expanded{
                    @apply hidden;

                    @screen md {
                        @apply block;
                    }
                }
            }
        }
    }
</style>