<template>
    <loading-screen v-if="is_loading_application"/>
    <div v-else-if="!is_loading_application && application" class="page-container">
        <h1>{{$t('account.my_account')}}</h1>

        <div class="content-container">
            <div class="header-row">
                <div class="detail">
                    <button class="back-button" @click="$router.go(-1)">
                        <font-awesome-icon :icon="['far','long-arrow-left']" />
                    </button>

                    <div class="detail-content">
                        <h2>{{application.attributes.reference}}</h2>
                        <p>{{computedStatus(application.attributes.status)}}</p>
                    </div>
                </div>

                <div class="detail">
                    <h3>{{application.relationships.template.data.id}}. {{application.relationships.template.data.attributes.name}}</h3>
                </div>
            </div>

            <div class="form-container">
                <div class="steps-nav-container">
                    <div class="step" :class="{active: active_step === i}" v-for="(step, i) in steps">
                        <p>{{i + 1}}</p>
                    </div>
                </div>

                <step v-for="(step, i) in steps" :step="step" :ref="`step-${i}`" v-show="i === active_step" :key="step.id" :disabled="true"/>

                <div class="buttons-container">
                    <button class="button --grey" v-if="active_step !== 0" @click="prevStep">{{$t('back')}}</button>
                    <button v-if="active_step !== steps.length-1" class="button --primary next-button" @click="nextStep">{{$t('next')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Step from "@/components/form/Step";
import LoadingScreen from "@/components/LoadingScreen.vue";

export default {
    name: "Application",
    components: {LoadingScreen, Step},
    data() {
        return {
            active_step: 0,
            application: null,
            steps: [],
            is_loading_application: false,
            is_loading_application_steps: false,
        }
    },
    methods: {
        nextStep() {
            this.active_step++;
            window.scrollTo(0, 0);
        },
        prevStep() {
            this.active_step--;
            window.scrollTo(0, 0);
        },
        computedStatus(status) {
            if(status === 'vetting_in_progress')
                return this.$t('account.vetting_in_progress');
            else if(status === 'completed')
                return this.$t('account.completed');
            else if(status === 'rejected')
                return this.$t('account.rejected');
            else
                return 'N/A'
        },
        getApplication() {
            this.is_loading_application = true;

            this.$axios.get(`applications/${this.$route.params.id}`)
                .then(({data}) => {
                    this.application = data.data;

                    this.is_loading_application = false;
                })
                .catch(e => {
                    this.is_loading_application = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('forms.error_retrieve_application')),
                        type: 'error',
                    });
                });
        },
        getApplicationSteps() {
            this.is_loading_application_steps = true;

            this.$axios.get(`applications/${this.$route.params.id}/steps`)
                .then(({data}) => {
                    this.steps = data.data;

                    this.is_loading_application_steps = false;
                })
                .catch(e => {
                    this.is_loading_application_steps = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('forms.error_retrieve_application_steps')),
                        type: 'error',
                    });
                });
        }
    },
    async mounted() {
        await this.getApplication();
        this.getApplicationSteps();
    }
}
</script>

<style lang="scss" scoped>
.page-container {
    @apply px-4;

    @screen md {
        @apply px-8;
    }

    @screen xl {
        @apply px-48;
    }

    h1 {
        @apply text-4xl font-bold text-secondary bg-grey-lighter py-8;

        @screen xl {
            @apply text-5xl pt-14 py-12;
        }
    }

    & > .content-container {
        @apply w-full rounded-lg bg-white px-6 py-8 mb-8;
        box-shadow: 0px 3px 6px #00000029;

        @screen md {
            @apply p-8;
        }

        @screen lg {
            @apply mb-16;
        }

        @screen xl {
            @apply p-10;
        }

        & > .header-row {
            @apply flex flex-col;

            @screen md {
                @apply flex-row mb-8;
            }

            & > .detail {
                @apply flex flex-row;

                @screen md {
                    @apply flex-row;
                }

                &:first-of-type {
                    @apply pb-8 mb-8 border-b-2 border-primary;

                    @screen md {
                        @apply pb-0 mb-0 mr-8 border-b-0;
                    }
                }

                & > .back-button {
                    @apply mr-6 my-auto;

                    & > svg {
                        @apply text-primary text-3xl;
                    }

                    @screen xl {
                        @apply mr-8;
                    }
                }

                & > .detail-content {
                    @apply flex flex-col;

                    & > h2 {
                        @apply text-3xl text-secondary font-bold mb-3 border-r-2 border-primary pr-8;
                    }

                    & > p {
                        @apply text-grey-dark font-semibold;
                    }
                }

                h3 {
                    @apply text-2xl text-secondary font-bold;

                    @screen lg {
                        @apply text-3xl
                    }
                }
            }
        }

        .form-container {
            @apply mx-auto w-full max-w-3xl;

            & > .step-wrapper {
                @apply -mx-4;

                @screen md {
                    @apply mx-0;
                }
            }

            & > .steps-nav-container {
                @apply flex flex-row my-8;

                @screen md {
                    @apply px-0;
                }

                @screen xl {
                    @apply my-12;
                }

                & > .step {
                    @apply w-9 h-9 mr-8 bg-grey-lighter border-2 border-grey rounded-full flex flex-wrap;

                    &.active {
                        @apply border-primary bg-white;
                    }

                    & > p {
                        @apply m-auto text-secondary font-semibold;
                    }
                }
            }

            & > .buttons-container {
                @apply my-8 px-4 flex flex-row justify-between w-full;

                @screen md {
                    @apply px-0 max-w-3xl;
                }

                & > .next-button {
                    @apply ml-auto;
                }
            }
        }
    }
}
</style>