<template>
    <div class="register-page-container darken">
        <div class="content">
            <img src="../assets/logo.svg" class="logo " alt="logo"/>

            <Form v-if="screen === 'register'" @submit="createAccount" :disabled="is_processing"
                  class="form-container create-account-form">
                <h1>{{ $t('auth.create_account') }}</h1>

                <FormInputText v-model="$v.details.entity.$model" identifier="entity"
                               :placeholder="$t('users.entity')" :disabled="is_processing"
                               :has-error="$v.details.entity.$error" autocomplete="new-password">
                    <template v-slot:errors>
                        <p v-if="!$v.details.entity.required">
                            {{ $t('validation.x_is_required', {x: $t('users.entity')}) }}
                        </p>
                    </template>
                </FormInputText>

                <FormInputText v-model="$v.details.contact_number.$model" identifier="contact_number"
                               :placeholder="$t('users.contact_number')" :disabled="is_processing"
                               :has-error="$v.details.contact_number.$error" autocomplete="new-password">
                    <template v-slot:errors>
                        <p v-if="!$v.details.contact_number.required">
                            {{ $t('validation.x_is_required', {x: $t('users.contact_number')}) }}
                        </p>
                    </template>
                </FormInputText>

                <FormInputPassword v-model="$v.details.password.$model" identifier="password"
                                   :placeholder="$t('auth.enter_your_new_password')" :disabled="is_processing" type="password"
                                   :has-error="$v.details.password.$error" autocomplete="new-password">
                    <template v-slot:errors>
                        <p v-if="!$v.details.password.required">
                            {{$t('validation.x_is_required',{x: $t('users.password')})}}
                        </p>
                        <p v-else-if="!$v.details.password.minLength">
                            {{ $t('validation.password_must_be_atleast_x_characters',{x: 8}) }}
                        </p>
                    </template>
                </FormInputPassword>

                <FormInputPassword v-model="$v.details.password_confirmation.$model" identifier="password"
                                   :placeholder="$t('auth.retype_password')" :disabled="is_processing" type="password"
                                   :has-error="$v.details.password_confirmation.$error" autocomplete="new-password">
                    <template v-slot:errors>
                        <p v-if="!$v.details.password_confirmation.required">
                            {{$t('validation.x_is_required',{x: $t('auth.password')})}}
                        </p>
                        <p v-else-if="!$v.details.password_confirmation.sameAs">
                            {{$t('validation.passwords_must_match')}}
                        </p>
                    </template>
                </FormInputPassword>

                <div class="submit-row">
                    <Button :disabled="is_processing" className="--primary" :class="{'spinner-black': is_processing}"
                            type="submit">
                        {{ $t('auth.create_account') }}
                    </Button>
                </div>
            </Form>
            <Form v-else-if="screen === 'account-created'" class="success-form">
                <p>{{ $t('auth.your_account_created') }}</p>

                <font-awesome-icon :icon="['fal','check-circle']"/>
                <div class="submit-row">
                    <Button className="--primary" :onclick="goToLogin">
                        {{ $t('nav.login') }}
                    </Button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import {required, requiredIf, sameAs} from 'vuelidate/lib/validators';
import Button from "../components/Button";
import Form from "../components/form/Form";
import FormInputText from "../components/form/FormInputText";
import FormInputPassword from "../components/form/FormInputPassword";

export default {
    name: "register-page",
    components: {FormInputText, FormInputPassword, Form, Button},
    data() {
        return {
            details: {
                entity: null,
                contact_number: null,
                password: null,
                password_confirmation: null,
            },
            screen: 'account-created',
            show_password: false,
            is_validating: false,
            is_processing: false,
        }
    },
    validations: {
        details: {
            entity: {required},
            contact_number: {required},
            password: { required },
            password_confirmation: {
                required: requiredIf(function (u) {
                    return u.password;
                }),
                passwordConfirmation: sameAs('password'),
            },
        }
    },
    computed: {
        year() {
            return new Date().getFullYear();
        }
    },
    methods: {
        createAccount() {
            this.$v.details.$touch();
            if (this.$v.details.$anyError || this.is_processing)
                return;

            this.is_processing = true;

            let payload = {
                token: this.$route.query.token,
                entity: this.details.entity,
                contact_number: this.details.contact_number,
                password: this.details.password,
                password_confirmation: this.details.password_confirmation
            };

            this.$axios.post('auth/register', payload)
                .then(({data}) => {
                    this.screen = 'accounte-created'

                    this.is_processing = false;
                })
                .catch((e) => {
                    this.is_processing = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('auth.failed_create_account')),
                        type: 'error',
                    });
                });
        },
        async validateToken() {
            this.is_validating = true;

            await this.$axios
                .post(`auth/invite/validate`, {token: this.token})
                .then(({data}) => {
                    this.is_validating = false;
                })
                .catch((e) => {
                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data.errors, this.$t('auth.unauthorized')),
                        type: 'error',
                    });

                    this.$router.push({name: 'login', params: {locale: this.$store.state.locale}});
                });
        },
        goToLogin() {
            this.$router.push({name: 'login'});
        },
    },
    async mounted() {
        if (this.$route.query.token) {
            this.token = this.$route.query.token;
            await this.validateToken();
        } else
            this.$router.push({name: 'login'});
    },
    head() {
        return {
            title: {
                inner: this.$t('auth.create_account')
            },
        }
    }
}
</script>
<style lang="scss" scoped>
.register-page-container {
    @apply bg-cover bg-center bg-no-repeat bg-secondary h-full w-full flex flex-col justify-center items-center;
    height: calc(100vh - 84px);

    .content {
        @apply px-10 py-10 rounded-lg flex flex-col max-w-full bg-white;

        width: 90vw;
        //background-color: rgba(0, 0, 0, 0.65);
        z-index: 1;

        @screen sm {
            width: 478px;
        }

        h1 {
            @apply text-secondary font-bold text-lg text-center mb-8;
        }

        .logo {
            @apply h-14 w-auto mb-8 object-contain;
        }

        .form-container {
            @apply bg-white;

            & > .input-group {
                margin-bottom: 1rem !important;

                input {
                    color: theme('colors.black') !important;
                    height: 50px;
                }

                .password-field {
                    height: 50px;

                    & > button {
                        @apply mt-1;
                    }
                }
            }

            .submit-row {
                @apply flex flex-row justify-between items-center mt-2;

                a.forgot {
                    @apply text-black text-sm font-bold no-underline cursor-pointer;
                }
            }
        }

        .create-account-form {
            @apply flex flex-col items-center;

            .input-group {
                @apply flex flex-col items-start mb-4 w-full;

                & > label {
                    @apply text-sm block text-black font-bold mb-2 ml-4;

                    small {
                        @apply text-xs;
                    }
                }

                & > .input-wrapper, &.input-group-with-button > .input-button-wrapper > .input-wrapper {
                    @apply w-full flex flex-col;
                }

                .input-wrapper {
                    input, textarea {
                        @apply bg-transparent text-black border-2 border-primary p-4;

                        &:focus {
                            @apply border-primary outline-none;
                        }
                    }
                }

                &.error {
                    input, textarea, .password-field {
                        border-color: theme('colors.negative') !important;
                    }
                }

                &.input-group--password {
                    & > .input-wrapper {
                        @apply w-full flex flex-col border-0 rounded;

                        & > .password-field {
                            @apply rounded bg-transparent border-2 border-primary w-full p-0 flex flex-row items-center justify-between;

                            input {
                                @apply border-0 bg-transparent text-black text-base w-full appearance-none flex-1;
                            }

                            button {
                                @apply p-0 ml-4 mr-4;

                                &:active, &:focus {
                                    @apply outline-none;
                                }

                                svg {
                                    @apply cursor-pointer text-grey text-lg ml-4;
                                }
                            }

                            &:focus-within {
                                @apply border-primary;
                            }
                        }
                    }

                    ::-webkit-input-placeholder { /* Edge */
                        color: theme('colors.black') !important;
                        font-weight: normal;
                        opacity: 1 !important;
                        font-size: 14px !important;
                    }

                    :-ms-input-placeholder { /* Internet Explorer 10-11 */
                        color: theme('colors.black') !important;
                        font-weight: normal;
                        opacity: 1 !important;
                        font-size: 14px !important;
                    }

                    ::placeholder {
                        color: theme('colors.black') !important;
                        font-weight: normal;
                        opacity: 1 !important;
                        font-size: 14px !important;
                    }
                }

                .errors {
                    @apply mt-3 mb-1;

                    p {
                        @apply text-negative italic text-sm py-1 ml-4;
                    }
                }

                .submit-row {
                    @apply flex flex-row justify-between items-center w-full;

                    button {
                        @apply w-full;
                    }
                }
            }
        }

        .success-form {
            @apply flex flex-col items-center;

            & > p {
                @apply text-black font-medium text-base text-center max-w-92 mt-2;
            }

            svg {
                @apply text-secondary text-8xl my-12;
            }

            .submit-row {
                @apply flex flex-row justify-between items-center w-full;

                button {
                    @apply mx-auto cursor-pointer;
                }
            }
        }
    }

    .footer-container {
        @apply flex flex-col bg-secondary w-full py-8 mt-auto;
        grid-area: footer;

        @screen md {
            @apply flex flex-row items-center justify-between;
        }

        .copyright-text {
            @apply pb-4 mb-4 border-b-2 border-primary text-white text-xxs text-center mx-2;

            @screen md {
                @apply pb-0 mb-0 border-b-0 pr-8 mr-8 border-r-2 border-primary text-xs ml-auto;
            }

            @screen lg {
                @apply text-sm;
            }
        }

        .engineered-by-arkafort {
            @apply flex flex-row mx-auto;

            @screen md {
                @apply ml-0;
            }

            & > p {
                @apply text-xs text-white;

                @screen lg {
                    @apply text-sm;
                }
            }

            & > img {
                @apply ml-4 h-4;
            }
        }
    }
}
</style>
