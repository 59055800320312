<template>
    <nav class="menubar-container">
        <img src="../assets/logo.svg" class="logo">

        <nav>
            <ul>
                <li>
                    <router-link :to="{name: 'forms_index'}">{{$t('nav.home')}}</router-link>
                </li>
            </ul>
        </nav>

        <div class="user" :name="`nav`">
            <font-awesome-icon :icon="['far', 'user-circle']"/>
        </div>

        <tippy :to="`nav`" :interactive="true" hideOnClick="toggle" placement="bottom-end" theme="menu-drop">
            <menu-drop/>
        </tippy>
    </nav>
</template>

<script>
    import Avatar from 'vue-avatar';
    import UserIcon from './UserIcon';
    import MenuDrop from './MenuDrop';

    export default {
        name: "Navbar",
        components: {UserIcon, Avatar, MenuDrop},
        methods: {
            async logout() {
                await this.$store.dispatch('logout');
                this.$router.push({name: 'login'});
            },
        }
    }
</script>

<style lang="scss" scoped>
.menubar-container {
    @apply flex flex-row w-full bg-white h-28 text-white relative justify-between;
    grid-area: menubar;

    img.logo {
        @apply h-12 my-auto w-auto ml-4;

        @screen md {
            @apply ml-8;
        }

        @screen xl {
            @apply ml-48;
        }
    }

    nav {
        @apply my-auto ml-auto mr-6;

        ul {
            @apply text-secondary font-bold text-secondary text-sm;
        }
    }

    .user {
        @apply my-auto items-center mr-4;

        @screen md {
            @apply mr-8;
        }

        @screen xl {
            @apply mr-48;
        }

        & > svg {
            @apply text-secondary text-2xl;
        }

        &:hover {
            @apply cursor-pointer;
        }
    }

}
</style>