<template>
    <div class="content-container">
        <h2>{{$t('account.my_applications')}}</h2>

        <div class="status-filter">
            <ul>
                <li @click="onStatusChange('vetting_in_progress')" :class="{active: filter_status === 'vetting_in_progress'}">{{$t('account.vetting_in_progress')}}</li>
                <li @click="onStatusChange('completed')" :class="{active: filter_status === 'completed'}">{{$t('account.completed')}}</li>
                <li @click="onStatusChange('rejected')" :class="{active: filter_status === 'rejected'}">{{$t('account.rejected')}}</li>
            </ul>
        </div>

        <div class="cards-container" v-if="applications.length">
            <div class="card-wrapper" v-for="application in applications">
                <div class="card-detail">
                    <p class="label">{{$t('account.reference')}}</p>
                    <p>{{application.attributes.reference}}</p>
                </div>

                <div class="card-detail">
                    <p class="label">{{$t('account.last_updated_at')}}</p>
                    <p>{{$moment(application.attributes.updated_at).format('DD/MM/YYYY - HH:mm')}}</p>
                </div>

                <div class="card-detail">
                    <p class="label">{{$t('account.status')}}</p>
                    <p>{{computedStatus(application.attributes.status)}}</p>
                </div>

                <router-link tag="button" class="button --primary --mini" :to="{name: 'application', params:{id: application.id}}">{{$t('view')}}</router-link>
            </div>
        </div>

        <p class="no-applications-text" v-else-if="!applications.length && !is_loading_applications">No applications with status {{computedStatus(filter_status)}}</p>

        <table v-if="applications.length">
            <thead>
                <tr>
                    <div class="details-container">
                        <th>{{$t('account.reference')}}</th>
                        <th>{{$t('account.last_updated_at')}}</th>
                        <th>{{$t('account.status')}}</th>
                    </div>
                </tr>
            </thead>

            <tr v-for="application in applications">
                <div class="details-container">
                    <td>{{application.attributes.reference}}</td>
                    <td>{{$moment(application.attributes.updated_at).format('DD/MM/YYYY - HH:mm')}}</td>
                    <td>{{computedStatus(application.attributes.status)}}</td>
                </div>

                <td class="view-button">
                    <router-link tag="button" class="button --primary --mini" :to="{name: 'application', params:{id: application.id}}">{{$t('view')}}</router-link>
                </td>
            </tr>
        </table>

        <div class="pagination-row" v-if="applications.length">
            <p>{{$t('pagination.showing_x_of_y_entries', {x: `${(current_page * 5) - 4} - ${current_page * 5}`, y: applications.length})}}</p>

            <div class="buttons-container">
                <button @click="onPageChange(current_page-1)" :class="{disabled: current_page === 1}"><font-awesome-icon :icon="['fal', 'chevron-left']"/></button>
                <button @click="onPageChange(current_page+1)" class="{disabled: current_page === last_page}"><font-awesome-icon :icon="['fal', 'chevron-right']"/></button>
            </div>

            <div class="page-numbers-container">
                <p @click="onPageChange(1)" :class="{disabled: current_page === 1}">{{$t('pagination.first')}}</p>
                <p @click="onPageChange(serverParams.page - 1)" :class="{disabled: current_page <= 1}">{{$t('pagination.prev')}}</p>

                <p v-if="current_page > 1" :class="{active: serverParams.page === current_page-1}" @click="onPageChange(current_page-1)">{{current_page-1}}</p>

                <p :class="{active: serverParams.page === current_page}" @click="onPageChange(current_page)">{{current_page}}</p>

                <p v-if="current_page < last_page" :class="{active: serverParams.page === current_page+1}" @click="onPageChange(current_page+1)">{{current_page+1}}</p>

                <p @click="onPageChange(serverParams.page + 1)" :class="{disabled: current_page >= last_page}">{{$t('pagination.next')}}</p>
                <p @click="onPageChange(last_page)" :class="{disabled: current_page === last_page}">{{$t('pagination.last')}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/components/Button";
import LoadingScreen from "@/components/LoadingScreen.vue";

export default {
    name: "MyApplications",
    components: {LoadingScreen, Button},
    data() {
        return {
            filter_status: 'vetting_in_progress',
            applications: [],
            current_page: 1,
            serverParams: {
                page: 1,
                per_page: 5,
                filters: [
                    {
                        filter_by: 'status',
                        filter_value: 'vetting_in_progress'
                    }
                ]
            },
            last_page: 10,
            is_loading_applications: false,
        }
    },
    methods: {
        computedStatus(status) {
            if(status === 'vetting_in_progress')
                return this.$t('account.vetting_in_progress');
            else if(status === 'completed')
                return this.$t('account.completed');
            else if(status === 'rejected')
                return this.$t('account.rejected');
            else
                return 'N/A'
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
            this.getApplications();
        },
        onPageChange(params) {
            this.updateParams({page: params});
        },
        onStatusChange(status) {
            this.filter_status = status;

            this.serverParams = {
                page: 1,
                per_page: 10
            };

            let filters = [
                {
                    filter_by: 'status',
                    filter_value: status
                }
            ]

            this.updateParams({filters: filters});
        },
        getApplications() {
            this.is_loading_applications = true;

            this.$axios.get(`users/${this.$store.getters.user.id}/applications`, {params: this.serverParams})
                .then(({data}) => {
                    this.applications = data.data;
                    this.current_page = data.meta.current_page;
                    this.last_page = data.meta.last_page;
                    this.is_loading_applications = false;
                })
                .catch(e => {
                    this.is_loading_applications = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('forms.error_retrieve_applications')),
                        type: 'error',
                    });
                });
        }
    },
    mounted() {
        this.getApplications();
    }
}
</script>

<style lang="scss" scoped>
    .content-container {
        h2 {
            @apply text-3xl font-bold text-secondary mb-12;

            @screen md {
                @apply text-3xl;
            }
        }

        .status-filter {
            @apply mb-8 overflow-x-scroll;

            @screen sm {
                @apply overflow-x-hidden;
            }

            @screen lg {
                @apply mb-12;
            }

            & > ul {
                @apply flex flex-row;

                & > li {
                    @apply mr-12 pb-2 font-semibold text-grey cursor-pointer whitespace-nowrap;

                    &.active {
                        @apply text-secondary border-b-2 border-primary;
                    }
                }
            }
        }

        & > .cards-container {
            @apply flex flex-col;

            & > .card-wrapper {
                @apply grid grid-cols-2 grid-rows-2 gap-x-4 gap-y-6 mb-8 rounded-lg p-6;
                box-shadow: 0px 3px 6px #00000029;

                @screen lg {
                    @apply hidden;
                }

                & > .card-detail {
                    @apply flex flex-col text-xs text-secondary;

                    @screen sm {
                        @apply text-sm;
                    }

                    & > .label {
                        @apply font-bold mb-1;
                    }
                }

                & > button {
                    @apply w-16 rounded-lg;
                }
            }
        }

        & > .no-applications-text {
            @apply text-sm text-grey pt-6 pb-12;

            @screen lg {
                @apply pb-16;
            }
        }

        & > table {
            @apply w-full text-secondary text-sm hidden;

            @screen lg {
                @apply grid w-full;
            }

            @screen 2xl {
                @apply text-lg;
            }

            tr {
                @apply px-12 mb-8 flex font-semibold;

                th {
                    @apply text-left w-1/3;
                }

                .details-container {
                    @apply w-5/6 flex;

                    td {
                        @apply w-1/3 my-auto;
                    }
                }

                td {
                    @apply w-1/3;

                    &.view-button {
                        @apply flex w-auto ml-auto;

                        button {
                            @apply ml-auto;
                        }
                    }
                }
            }

            & > tr {
                @apply py-5 rounded-lg;
                box-shadow: 0px 3px 6px #00000029;

                & > .content-container {
                    & > td {
                        @apply flex my-auto;
                    }
                }
            }
        }
    }
</style>