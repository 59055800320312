<template>
    <div class="cookies-bar">
        <div class="text">
            <p>{{$t('cookies_text')}}</p>

            <a href="/policy">{{$t('see_privacy_policy')}}</a>
        </div>

        <div class="buttons">
            <Button class="button --secondary --outline --mini" :onclick="() => declineCookies()">{{$t('decline')}}</Button>
            <Button class="button --secondary --mini" :onclick="() => acceptCookies()">{{$t('accept')}}</Button>
        </div>
    </div>
</template>

<script>
import Button from "@/components/Button.vue";

export default {
    name: "CookiesBar",
    components: {Button},
    methods: {
        acceptCookies() {
            this.$store.commit('ACCEPT_COOKIES');
        },
        declineCookies() {
            this.$store.commit('DECLINE_COOKIES');
        }
    }
}
</script>

<style lang="scss" scoped>
    .cookies-bar {
        @apply flex flex-row flex-wrap bg-primary p-6 justify-between relative;

        & > .text {
            @apply flex flex-row flex-wrap w-full text-white text-12 text-center mb-4;

            @screen lg {
                @apply w-auto my-auto;
            }

            & > * {
                @apply w-full;

                @screen lg {
                    @apply w-auto;
                }

                &:first-of-type {
                    @apply mb-2;

                    @screen lg {
                        @apply mb-0;
                    }
                }
            }

            & > a {
                @apply underline ml-1 font-bold;
            }
        }

        & > .buttons {
            @apply flex w-full;

            @screen lg {
                @apply w-auto;
            }

            > .button {
                &:first-of-type {
                    @apply ml-auto;
                }

                &:last-of-type {
                    @apply ml-2 mr-auto;
                }
            }

            & > svg {
                @apply text-xl text-white my-auto ml-4 cursor-pointer absolute;
                top: 10px;
                right: 10px;

                @screen lg {
                    @apply block static;
                }
            }
        }
    }
</style>