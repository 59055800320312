<template>
    <loading-screen v-if="is_checking_pending || is_creating_application || is_loading_form"/>
    <div v-else-if="form && !is_checking_pending && !is_creating_application && !is_loading_form && !is_application_submitted" class="page-container">
        <h1>{{form.id}}. {{form.attributes.name}}</h1>

        <div class="form-container">
            <div class="steps-nav-container">
                <div class="step" :class="{active: active_step === i}" v-for="(step, i) in form.relationships.steps">
                    <p>{{i + 1}}</p>
                </div>
            </div>

            <step v-for="(step, i) in form.relationships.steps" :step="step" :ref="`step-${i}`" v-show="i === active_step" :key="step.id"/>

            <div class="buttons-container">
                <button class="button --grey" v-if="active_step !== 0" @click="prevStep">{{$t('back')}}</button>
                <button v-if="active_step !== form.relationships.steps.length-1" class="button --primary next-button" @click="nextStep">{{$t('next')}}</button>
                <button v-else class="button --primary submit-button" @click="submitApplication" :class="{spinner: is_submitting_application}">{{$t('submit')}}</button>
            </div>
        </div>
    </div>
    <div v-else-if="is_application_submitted" class="submitted-container">
        <font-awesome-icon :icon="['fal','check-circle']"/>
        <h1>{{$t('forms.your_application_has_been_submitted')}}</h1>
        <router-link tag="button" class="button --primary --outline" :to="{name: 'my_applications'}">{{$t('account.my_applications')}}</router-link>
    </div>
</template>

<script>
import LoadingScreen from "../../components/LoadingScreen";
import Step from "@/components/form/Step"

export default {
    name: "ApplicationForm",
    components: {LoadingScreen, Step},
    data() {
         return {
             steps: [],
             active_step: 0,
             form: null,
             is_checking_pending: false,
             is_creating_application: false,
             is_loading_form: false,
             is_submitting_application: false,
             is_application_submitted: false
         }
    },
    methods: {
        createApplication() {
            this.is_creating_application = true;

            this.$axios.post(`applications`, {template_id: this.$route.params.id})
                .then(({data}) => {
                    this.is_creating_application = false;
                    this.$router.replace({name: 'form', query: {application: data.data.id}})
                })
                .catch(e => {
                    this.is_creating_application = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('permissions.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        checkPending() {
            this.is_checking_pending = true;

            this.$axios.post(`users/${this.$store.getters.user.id}/applications/pending`, {template_id: this.$route.params.id})
                .then(({data}) => {
                    this.is_checking_pending = false;

                    if(!data) {
                        this.createApplication();
                    } else {
                        this.$router.replace({name: 'form', query: {application: data.data.id}})
                    }
                })
                .catch(e => {
                    this.is_checking_pending = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('permissions.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        getForm() {
            this.is_loading_form = true;

            this.$axios.get(`templates/${this.$route.params.id}`)
                .then(({data}) => {
                    this.form = data.data;

                    this.is_loading_form = false;
                })
                .catch(e => {
                    this.is_loading_form = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('forms.error_retrieve_form')),
                        type: 'error',
                    });
                });
        },
        nextStep() {
            if (!this.$refs[`step-${this.active_step}`][0].checkFieldsValid())
                return;

            this.active_step++;
            window.scrollTo(0, 0);
        },
        prevStep() {
            this.active_step--;
            window.scrollTo(0, 0);
        },
        submitApplication() {
            if (!this.$refs[`step-${this.active_step}`][0].checkFieldsValid()) return;

            this.is_submitting_application = true;

            this.$axios.get(`applications/${this.$route.query.application}/submit`)
                .then(({}) => {
                    this.is_submitting_application = false;
                    this.is_application_submitted = true;
                })
                .catch(e => {
                    this.is_submitting_application = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('forms.error_submit_application')),
                        type: 'error',
                    });
                });
        }
    },
    mounted() {
        this.checkPending();
        this.getForm();
    }
}
</script>

<style lang="scss" scoped>
    .page-container {
        @apply bg-white h-full;

        h1 {
            @apply text-lg font-bold text-secondary py-8 bg-grey-lighter pl-4;

            @screen md {
                @apply text-2xl pl-8;
            }

            @screen xl {
                @apply text-3xl pl-48;
            }
        }

        .form-container {
            @apply mx-auto w-full max-w-3xl;

            & > .steps-nav-container {
                @apply flex flex-row my-8 px-4;

                @screen md {
                    @apply px-0;
                }

                @screen xl {
                    @apply my-12;
                }

                & > .step {
                    @apply w-9 h-9 mr-16 bg-grey-lighter border-2 border-grey rounded-full flex;

                    &.active {
                        @apply border-primary bg-white;
                    }

                    & > p {
                        @apply m-auto text-secondary font-semibold;
                    }
                }
            }

            & > .buttons-container {
                @apply my-8 px-4 flex flex-row justify-between w-full;

                @screen md {
                    @apply px-0 max-w-3xl;
                }

                & > .next-button, .submit-button {
                    @apply ml-auto;
                }
            }
        }
    }

    .submitted-container {
        @apply flex flex-col bg-white h-full py-8;

        @screen md {
            @apply py-12;
        }

        @screen xl {
            @apply py-16;
        }

        & > svg {
            @apply text-7xl text-secondary mx-auto mb-8;

            @screen xl {
                @apply text-8xl;
            }
        }

        & > h1 {
            @apply text-lg font-bold text-secondary text-center mx-auto mb-8;

            @screen md {
                @apply text-2xl;
            }

            @screen xl {
                @apply text-3xl;
            }
        }

        & > button {
            @apply mx-auto w-auto;
        }
    }
</style>