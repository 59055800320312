export default {
    nav: {
        home: 'Home',
        login: 'Login',
        reset_password: 'Reset Password',
        reports: 'Reports',
        roles_permissions: 'Roles & Permissions',
        users: 'Users',
        profile: 'Profile',
        logout: 'Logout',
        signup: 'Sign Up',
    },
    profile: {
        profile: 'profile',
        success_updated: 'Your account has been updated',
        password_updated: 'Your password has been updated',
        error_update: 'Failed to update profile',
        password_error_update: 'Failed to update password',
    },
    account: {
        my_account: 'My Account',
        my_details: 'My details',
        my_applications: 'My applications',
        payment_methods: 'Payment methods',
        payments_history: 'Payments history',
        account_settings: 'Account settings',
        personal_information: 'Personal information',
        name: 'Name',
        contact_number: 'Contact number',
        email_address: 'Email address',
        entity: 'Entity',
        update_password: 'Update password',
        new_password: 'New password',
        vetting_in_progress: 'Vetting In Progress',
        completed: 'Completed',
        rejected: 'Rejected',
        reference: 'Reference',
        last_updated_at: 'Last updated at',
        status: 'Status',
        error_retrieve_applications: 'Failed to retrieve applications',
    },
    users: {
        users: 'users',
        add_user: 'Add User',
        edit_user: 'Edit User',
        search_users: 'Search Users',
        name: 'Name',
        company: 'Company',
        mobile_number: 'Mobile Number',
        phone_mobile_number: 'Phone / Mobile Number',
        contact_number: 'Contact Number',
        entity: 'Entity',
        address: 'Address',
        email: 'Email',
        phone: 'Phone',
        email_address: 'Email Address',
        organisation: 'Organisation',
        company_name: 'Company Name',
        contact_person: 'Contact Person',
        vat_number: 'VAT Number',
        city: 'City',
        post_code: 'Post Code',
        password: 'Password',
        confirm_password: 'Confirm password',
        role: 'Role',
        roles: 'Roles',
        actions: 'Actions',
        prompt_delete: 'Are you sure you want to delete this user?',
        success_created: 'User has been created',
        success_updated: 'User has been updated',
        error_create: 'Failed to create user',
        error_update: 'Failed to update user',
        error_delete: 'Failed to delete user',
        error_retrieve: 'Failed to retrieve users',
        user_details: 'User Details',
        update_user: 'Update User',
        delete_user: 'Delete User',
    },
    roles: {
        roles: 'roles',
        permissions: 'Permissions',
        permission: 'Permission',
        actions: 'Actions',
        name: 'Name',
        add_role: 'Add Role',
        edit_role: 'Edit Role',
        search_roles: 'Search Roles',
        prompt_delete: 'Are you sure you want to delete this role?',
        prompt_delete_subtext: '',
        error_retrieve: 'Failed to retrieve roles',
        error_create: 'Failed to create role',
        error_update: 'Failed to update role',
        error_delete: 'Failed to delete role',
        success_created: 'role has been created',
        success_updated: 'role has been updated',
        error_retrieve_fields: 'Failed to retrieve fields',
        role_details: 'Role Details',
        delete_role: 'Delete Role',
    },
    permissions: {
        permission: 'permission',
        permissions: 'permissions',
        error_retrieve: 'Failed to retrieve permissions',
        error_save: 'Failed to save permission',
        details_saved: 'permission details have been saved',
        saved: 'permission has been saved',
    },
    prompts: {
        are_you_sure: 'are you sure?',
        do_you_want_to_delete_this_x: 'do you want to delete this {x}?',
    },
    validation: {
        x_is_required: '{x} is required',
        x_are_required: '{x} are required',
        please_enter_x: 'please enter {x}',
        please_enter_longer_x: 'please enter a longer {x}',
        please_enter_shorter_x: 'please enter a shorter {x}',
        please_enter_valid_x: 'please enter a valid {x}',
        please_choose_x: 'please choose {x}',
        please_enter_number: 'please enter a number',
        please_enter_number_between_x: 'please enter a number between {x} and {y}',
        please_enter_url: 'please enter a URL',
        passwords_must_match: 'Passwords must match',
        password_must_be_atleast_x_characters: 'Password must be at least {x} characters',
        must_be_a_valid_email: 'Must be a valid email',
    },
    auth: {
        unauthorized: 'Unauthorized',
        unauthenticated: 'Unauthenticated',
        forgot_password_q: 'Forgot password?',
        forgot_password: 'Forgot password',
        forgot_password_text: 'Enter your email and we\'ll send you a link to reset your password.',
        password_has_been_updated: 'Your password has been updated!',
        setup_your_account: 'Setup your account.',
        create_account: 'Create Account',
        account_created: 'Account successfully created',
        your_account_created: 'Your account has been created',
        email: 'Email',
        password: 'Password',
        password_is_required: 'Password is required',
        email_is_required: 'Email is required',
        reset_password: 'Reset Password',
        enter_valid_email: 'Enter a valid email',
        email_sent: 'Email successfully sent',
        return_to_login: 'Return to Login',
        enter_your_new_password: 'Enter your new password',
        retype_password: 'Re-type password',
        failed_login: 'Login Failed',
        failed_reset: 'Reset Failed',
        identity_verified: 'Your identity has been verified!',
        set_new_password: 'Set your new password',
        registration_requested: 'Registration Requested',
        link_sent: 'A registration link has been sent to your email address!',
        follow_instructions: 'Kindly follow the instructions we’ve sent you to register.',
        check_spam: 'If you don’t receive an email form us within a few minutes, please check your spam folder.',
        failed_signup: 'Signup Failed',
        failed_send_register_link: 'Failed to send registration link',
        failed_create_account: 'Failed to create account',
    },
    forms: {
        start_the_process: 'Start the process!',
        title: 'Title',
        add_another_object_id: 'Add another Object ID',
        subject: 'Subject (brief description)',
        dimensions: 'Dimensions (In the case of framed works, specify if dimensions are with or without frame)',
        materials_and_techniques: 'Materials and techniques',
        inscriptions_or_markings: 'Inscriptions or markings (e.g. signatures, distinguishing signs of deterioration/damage, labels)',
        date_or_period: 'Date or period',
        maker: 'Maker/artist',
        existing_inventory_accession_number: 'Existing inventory/accession number (if applicable)',
        upload_images_of_item: 'Upload images of the item (of good quality, including inscriptions or markings)',
        upload_proof_of_purchase: 'Upload proof of purchase',
        upload_proof_of_provenance: 'Upload proof of provenance',
        your_application_has_been_submitted: 'Your application has been submitted!',
        choose_files: 'Choose File/s',
        error_retrieve: 'Failed to get forms',
        error_retrieve_form: 'Failed to get form',
        error_retrieve_application: 'Failed to get application',
        error_retrieve_application_steps: 'Failed to get application steps',
        error_retrieve_step_questions: 'Failed to get step questions',
        error_answer_question: 'Failed to answer question',
        error_submit_application: 'Failed to submit application',
        error_upload_file: 'Failed to upload file',
        error_upload_signature: 'Failed to upload signature',
        error_remove_file: 'Failed to remove file',
        error_download_file: 'Failed to download file',
        field_required: 'This field is required',
        select_at_least_one: 'Select at least one of the above',
        file_size_too_big: 'File size is too big',
    },
    pagination: {
        showing_x_of_y_entries: 'Showing {x} of {y} entries',
        first: 'First',
        last: 'Last',
        prev: 'Prev',
        next: 'Next'
    },
    cancel: 'Cancel',
    delete: 'Delete',
    decline: 'Decline',
    accept: 'Accept',
    remove: 'Remove',
    start: 'Start',
    finish: 'Finish',
    add: 'Add',
    update: 'Update',
    save: 'Save',
    view: 'View',
    print: 'Print',
    send: 'Send',
    search: 'Search',
    select: 'Select',
    page_not_found: 'Page not found',
    x_per_page: '{x} per page',
    error: 'Error',
    yes: 'Yes',
    no: 'No',
    accepted: 'Accepted',
    not_accepted: 'Not Accepted',
    warning: 'Warning',
    actions: 'Actions',
    back: 'Back',
    submit: 'Submit',
    assign: 'Assign',
    okay: 'Okay',
    signup: 'Sign Up',
    next: 'Next',
    cookies_text: 'We use cookies on our website to see how you interact with it. By accepting, you agree to our use of such cookies.',
    see_privacy_policy: 'See Privacy Policy'
}
