<template>
    <div class="form-errors">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "FormErrors"
    }
</script>

<style lang="scss" scoped>

</style>