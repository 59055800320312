<template>
    <div class="content-container">
        <h2>{{$t('account.payments_history')}}</h2>
    </div>
</template>

<script>
export default {
    name: "PaymentsHistory"
}
</script>

<style lang="scss" scoped>
    .content-container {
        h2 {
            @apply text-3xl font-bold text-secondary mb-12;

            @screen md {
                @apply text-3xl;
            }
        }
    }
</style>