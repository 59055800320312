<template>
    <div>
        <AppLayout>
            <router-view/>
        </AppLayout>
        <notifications position="bottom left" classes="notification-card"/>
    </div>
</template>

<script>
    import AppLayout from "./layouts/AppLayout";
    import NoLayout from "./layouts/NoLayout";

    export default {
        name: "App",
        components: {NoLayout, AppLayout}
    }
</script>
