<template>
    <div class="app-container">
        <main class="main-wrapper">
            <slot/>
            <Footer/>
        </main>
    </div>
</template>

<script>
    import Footer from "../components/Footer";
    import MobileMenu from "@/components/MobileMenu";
    import Navbar from "@/components/Navbar";

    export default {
        name: "NoLayout",
        components: {Footer}
    }
</script>

<style lang="scss" scoped>
.app-container {
    @apply w-full h-screen;

    .main-wrapper {
        @apply flex flex-col;

        main {
            @apply flex-1;

            &.mobile-menu-expanded{
                @apply hidden;

                @screen md {
                    @apply block;
                }
            }
        }
    }
}
</style>