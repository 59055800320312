import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import FormsIndex from "../views/forms/Index";
import ApplicationForm from "../views/forms/ApplicationForm";
import Login from "../views/Login";
import NotFound from "../views/NotFound";
import MyAccount from "../views/account/MyAccount";
import MyDetails from "../views/account/MyDetails";
import MyApplications from "../views/account/MyApplications";
import Application from "../views/account/Application";
import PaymentMethods from "../views/account/PaymentMethods";
import PaymentsHistory from "../views/account/PaymentsHistory";
import AccountSettings from "../views/account/AccountSettings";
import {i18n} from '@/locale/i18n';
import Reset from "@/views/Reset";
import RequestRegister from "../views/RequestRegister";
import Register from "../views/Register";
import Policy from "../views/policy/Policy.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/forms'
    },
    {
        path: '/forms',
        component: {
            render(c) {
                return c('router-view')
            }
        },
        children: [
            {
                path: '/',
                name: 'forms_index',
                component: FormsIndex,
            },
            {
                path: ':id',
                name: 'form',
                component: ApplicationForm,
            }
        ]
    },
    {
        path: '/account',
        name: 'account',
        redirect: 'account/details',
        component: MyAccount,
        children: [
            {
                path: 'details',
                name: 'my_details',
                component: MyDetails
            },
            {
                path: 'applications',
                name: 'my_applications',
                component: MyApplications
            },
            {
                path: 'payment-methods',
                name: 'payment_methods',
                component: PaymentMethods
            },
            {
                path: 'payments-history',
                name: 'payments_history',
                component: PaymentsHistory
            },
            {
                path: 'account-settings',
                name: 'account_settings',
                component: AccountSettings
            },
        ]
    },
    {
        path: '/account/applications/:id',
        name: 'application',
        component: Application
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            auth: 'guest',
        }
    },
    {
        path: '/register/request',
        name: 'request_register',
        component: RequestRegister,
        meta: {
            auth: 'guest',
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            auth: 'guest',
        }
    },
    {
        path: '/policy',
        name: 'policy',
        component: Policy,
        meta: {
            auth: 'guest',
        }
    },
    {
        path: '/password/reset',
        name: 'reset',
        component: Reset,
        meta: {
            auth: 'guest',
        }
    },
    {
        path: '*',
        name: 'notfound',
        component: NotFound,
        meta: {
            auth: false
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach(async (to, from, next) => {
    window.scrollTo(0, 0);

    if (!to.meta)
        next();

    // check auth
    if (to.meta.auth) {
        const routeAuth = to.meta.auth;

        if (routeAuth === true) {
            // user must be logged in
            if (!store.getters.token) {
                Vue.notify({
                    type: 'error',
                    text: i18n.t('auth.unauthenticated')
                });

                return next({name: 'login'});
            }
        } else if (routeAuth.toLowerCase() === 'guest') {
            // user must be logged out
            if (store.getters.token)
                return next({path: '/'});
        }
    }

    // check permission
    if (to.meta.any_permissions) {
        // check that the user has some of the required permissions

        if (!store.getters.hasAnyPermission(to.meta.any_permissions)) {
            Vue.notify({
                type: 'error',
                text: i18n.t('auth.unauthorized')
            });

            return next({path: '/'});
        }
    }

    // check permission
    if (to.meta.all_permissions) {
        // check that the user has all the required permissions

        let permissions = to.meta.all_permissions;
        if (!store.getters.hasAllPermissions(permissions)) {
            Vue.notify({
                type: 'error',
                text: i18n.t('auth.unauthorized')
            });

            return next({path: '/'});
        }
    }

    return next();
});

export default router;
