<template>
    <div class="content-container">
        <h2>{{ $t('account.my_details') }}</h2>

        <h3>{{ $t('account.personal_information') }}</h3>

        <Form @submit="save" :disabled="is_saving">
            <FormGroupTwo>
                <FormInputText v-model="$v.details.name.$model" :label="$t('account.name')"
                               :placeholder="$t('account.name')"
                               :disabled="is_loading_user || is_saving" :has-error="$v.details.name.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.details.name.required">
                            {{ $t('validation.x_is_required', {x: $t('account.name')}) }}
                        </p>
                    </template>
                </FormInputText>

                <FormInputText v-model="$v.details.email.$model" :placeholder="$t('account.email_address')"
                               :label="$t('account.email_address')"
                               :disabled="is_loading_user || is_saving" :has-error="$v.details.email.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.details.email.required">
                            {{ $t('validation.x_is_required', {x: $t('account.email_address')}) }}
                        </p>
                        <p v-if="$v.details.email.required && !$v.details.email.email">
                            {{ $t('validation.must_be_a_valid_email') }}
                        </p>
                    </template>
                </FormInputText>

                <FormInputText v-model="$v.details.contact_number.$model" :placeholder="$t('account.contact_number')"
                               :label="$t('account.contact_number')"
                               :disabled="is_loading_user || is_saving" :has-error="$v.details.contact_number.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.details.contact_number.required">
                            {{ $t('validation.x_is_required', {x: $t('account.contact_number')}) }}
                        </p>
                    </template>
                </FormInputText>

                <FormInputText v-model="$v.details.entity.$model" :placeholder="$t('account.entity')"
                               :label="$t('account.entity')"
                               :disabled="is_loading_user || is_saving" :has-error="$v.details.entity.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.details.entity.required">
                            {{ $t('validation.x_is_required', {x: $t('account.entity')}) }}
                        </p>
                    </template>
                </FormInputText>
            </FormGroupTwo>

            <Button :disabled="is_saving" className="--primary" :class="{'spinner-black': is_saving}" :onclick="save">
                <span>{{ $t('save') }}</span>
            </Button>
        </Form>
    </div>
</template>

<script>
import Form from '@/components/form/Form';
import FormGroupTwo from '@/components/form/FormGroupTwo';
import FormInputText from '@/components/form/FormInputText';
import FormInputDateTime from '@/components/form/FormInputDateTime';
import Button from '@/components/Button';
import {required, email} from 'vuelidate/lib/validators';

export default {
    name: "MyDetails",
    components: {Form, FormGroupTwo, FormInputText, FormInputDateTime, Button},
    data() {
        return {
            details: {
                name: null,
                email: null,
                contact_number: null,
                entity: null,
            },
            is_loading_user: true,
            is_saving: false
        }
    },
    validations: {
        details: {
            name: {required},
            email: {required, email},
            contact_number: {required},
            entity: {required},
        }
    },
    methods: {
        async getUser() {
            this.is_loading_user = true;

            await this.$axios.get(`profile`)
                .then(({data}) => {
                    this.original = data.data;
                    this.details.name = data.data.attributes.name;
                    this.details.email = data.data.attributes.email;
                    this.details.contact_number = data.data.attributes.contact_number;
                    this.details.entity = data.data.attributes.entity;

                    this.is_loading_user = false;
                })
                .catch(e => {
                    this.is_loading_user = false;

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$larerror(e.response.data, this.$t('users.error_retrieve')),
                        type: 'error',
                    });
                });
        },
        save() {
            this.$v.details.$touch();
            if (this.$v.details.$anyError || this.is_saving) return;

            let payload = {};

            if (this.original.attributes.name !== this.details.name)
                payload.name = this.details.name;

            if (this.original.attributes.email !== this.details.email)
                payload.email = this.details.email;

            if (this.original.attributes.contact_number !== this.details.contact_number)
                payload.contact_number = this.details.contact_number;

            if (this.original.attributes.entity !== this.details.entity)
                payload.entity = this.details.entity;

            if(!Object.keys(payload).length) return;

            this.is_saving = true;

            this.$axios.patch(`profile`, payload).then(({data}) => {
                this.$notify({
                    text: this.$t('profile.success_updated'),
                    type: 'success',
                });

                this.$store.dispatch('getUser');

                this.is_saving = false;
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('profile.error_update')),
                    type: 'error',
                });
            });
        }
    },
    mounted() {
        this.getUser();
    }
}
</script>

<style lang="scss" scoped>
.content-container {
    @apply max-w-4xl flex flex-col;

    h2 {
        @apply text-3xl font-bold text-secondary mb-12;

        @screen md {
            @apply text-3xl;
        }
    }

    h3 {
        @apply text-lg font-bold text-secondary mb-12 pb-4 border-b-2 border-grey-lighter;

        @screen md {
            @apply text-xl;
        }
    }

    .form-container {
        button {
            @apply mr-auto mt-2;
        }
    }
}
</style>