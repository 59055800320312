<template>
    <div class="login-page-container darken">
        <div class="content">
            <img src="../assets/logo.svg" class="logo " alt="logo"/>

            <Form v-if="screen === 'login'" @submit="login" :disabled="is_logging_in" class="form-container login-form">
                <FormInputText v-model="$v.credentials.email.$model" autocomplete="on" :placeholder="$t('users.email')"
                               :disabled="is_logging_in" :has-error="$v.credentials.email.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.credentials.email.required">
                            {{ $t('auth.email_is_required') }}
                        </p>
                        <p v-else-if="!$v.credentials.email.email">
                            {{ $t('auth.enter_valid_email') }}
                        </p>
                    </template>
                </FormInputText>

                <FormInputPassword v-model="$v.credentials.password.$model" identifier="password"
                                   :placeholder="$t('users.password')" :disabled="is_logging_in" type="password"
                                   :has-error="$v.credentials.password.$error" autocomplete="on">
                    <template v-slot:errors>
                        <p v-if="!$v.credentials.password.required">
                            {{ $t('validation.x_is_required', {x: $t('users.password')}) }}
                        </p>
                        <p v-else-if="!$v.credentials.password.minLength">
                            {{ $t('validation.password_must_be_atleast_x_characters', {x: 8}) }}
                        </p>
                    </template>
                </FormInputPassword>

                <div class="submit-row">
                    <Button :disabled="is_logging_in" className="--primary" :class="{'spinner-black': is_logging_in}"
                            type="submit">
                        <span>{{ $t('nav.login') }}</span>
                    </Button>

                    <a @click="screen = 'forgot'" class="forgot">
                        {{ $t('auth.forgot_password_q') }}
                    </a>
                </div>

                <div class="divider-row">
                    <div class="divider"/>
                    <p class="text">Or</p>
                    <div class="divider"/>
                </div>

                <Button :disabled="is_logging_in" className="--primary" :onclick="() => $router.push({name: 'request_register'})">
                    <span>{{ $t('auth.create_account') }}</span>
                </Button>
            </Form>

            <div v-else-if="screen === 'forgot'" class="forgot-wrapper">
                <button class="back" @click="screen = 'login'">
                    <font-awesome-icon :icon="['fal','long-arrow-left']"/>
                </button>
                <Form @submit="forgot" :disabled="is_logging_in" class="form-container forgot-form">
                    <p>{{ $t('auth.forgot_password_text') }}</p>

                    <FormInputText v-model="$v.credentials.email.$model" autocomplete="off"
                                   :has-error="$v.credentials.email.$error" :placeholder="$t('users.email')">
                        <template v-slot:errors>
                            <p v-if="!$v.credentials.email.required">
                                {{ $t('auth.email_is_required') }}
                            </p>
                            <p v-else-if="!$v.credentials.email.email">
                                {{ $t('auth.enter_valid_email') }}
                            </p>
                        </template>
                    </FormInputText>

                    <div class="submit-row">
                        <Button :disabled="is_logging_in" className="--primary mt-4"
                                :class="{'spinner-black': is_logging_in}" type="submit">
                            {{ $t('auth.reset_password') }}
                        </Button>
                    </div>
                </Form>
            </div>
            <Form v-else-if="screen === 'reset'" class="reset-form">
                <p>{{ $t('auth.email_sent') }}</p>

                <font-awesome-icon :icon="['fal','check-circle']"/>
                <div class="submit-row">
                    <Button className="--primary" :onclick="() => this.screen = 'login'">
                        {{ $t('nav.login') }}
                    </Button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
import {required, email} from 'vuelidate/lib/validators'
import Button from "../components/Button";
import Form from "../components/form/Form";
import FormInputText from "@/components/form/FormInputText";
import FormInputPassword from "@/components/form/FormInputPassword";

export default {
    name: "login-page",
    components: {FormInputPassword, FormInputText, Form, Button},
    data() {
        return {
            credentials: {
                email: '',
                password: '',
            },
            show_password: false,
            is_logging_in: false,
            screen: 'login',
        }
    },
    validations: {
        credentials: {
            email: {
                required,
                email,
            },
            password: {
                required,
            }
        }
    },
    methods: {
        login() {
            this.$v.credentials.$touch();
            if (this.$v.credentials.$anyError || this.is_logging_in)
                return;

            this.is_logging_in = true;
            this.$axios.post('auth/login', {
                email: this.$v.credentials.email.$model,
                password: this.$v.credentials.password.$model,
            }).then(async ({data}) => {
                this.$store.commit("SET_TOKEN", data.data.token);
                await this.$store.dispatch('getUser');

                this.is_logging_in = false;
                if (this.$store.getters.hasRole('organisation')) {
                    this.$router.push('bookings');
                } else {
                    this.$router.push('/');
                }
            }).catch(e => {
                this.is_logging_in = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('auth.failed_login')),
                    type: 'error',
                });
            });
        },
        forgot() {
            this.$v.credentials.email.$touch();
            if (this.$v.credentials.email.$anyError || this.is_logging_in)
                return;

            this.is_logging_in = true;
            this.$axios.post('auth/password/reset/request', {
                email: this.$v.credentials.email.$model,
            }).then(async ({data}) => {
                this.screen = 'reset';

                this.is_logging_in = false;
            }).catch(e => {
                this.is_logging_in = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('auth.failed_reset')),
                    type: 'error',
                });
            });
        }
    },
    head() {
        return {
            title: {
                inner: this.$t('nav.login')
            },
        }
    }
}
</script>

<style lang="scss" scoped>
.login-page-container {
    @apply bg-cover bg-center bg-no-repeat bg-secondary w-full flex flex-col justify-center items-center;
    height: calc(100vh - 84px);

    .content {
        @apply px-10 py-10 rounded-lg flex flex-col max-w-full bg-white;
        width: 90vw;
        //background-color: rgba(0, 0, 0, 0.65);
        z-index: 1;

        @screen sm {
            width: 478px;
        }

        .logo {
            @apply h-14 w-auto mb-10 object-contain;
        }

        .form-container {
            @apply bg-white;

            .submit-row {
                @apply flex flex-row justify-between items-center;
                margin-top: 0 !important;

                a.forgot {
                    @apply text-secondary text-sm no-underline cursor-pointer;
                }
            }

            .divider-row {
                @apply w-full flex flex-row my-8;

                & > .divider {
                    @apply border-b-2 border-grey-light mt-1 h-1/2 w-1/2;

                }

                & > p {
                    @apply mx-4 my-auto text-secondary font-bold text-sm;
                }
            }
        }

        .login-form {
            button[type="submit"] {
                @apply self-start;
            }

            .submit-row {
                @apply flex flex-row flex-wrap mt-3;

                button[type=button] {
                    @apply ml-2;
                }
            }
        }

        .forgot-wrapper {
            button.back {
                @apply absolute top-0 left-0;

                svg {
                    @apply text-white text-3xl mt-11 ml-10;
                }

                &:active, &:focus {
                    @apply outline-none;
                }
            }

            .forgot-form {
                @apply flex flex-col items-center;

                & > p {
                    @apply text-secondary font-normal leading-6 text-sm text-center mb-8;
                    max-width: 16rem;
                }
            }
        }

        .reset-form {
            @apply flex flex-col items-center mb-7;

            & > p {
                @apply text-secondary font-normal text-base text-center max-w-92 mt-3;
            }

            svg {
                @apply text-secondary my-8;
                font-size: 5.65rem;
            }

            .submit-row {
                @apply flex flex-row justify-between items-center w-full;

                button {
                    @apply mx-auto cursor-pointer;
                }
            }
        }
    }

    .footer-container {
        @apply flex flex-col bg-secondary w-full py-8 mt-auto;
        grid-area: footer;

        @screen md {
            @apply flex flex-row items-center justify-between;
        }

        .copyright-text {
            @apply pb-4 mb-4 border-b-2 border-primary text-white text-xxs text-center mx-2;

            @screen md {
                @apply pb-0 mb-0 border-b-0 pr-8 mr-8 border-r-2 border-primary text-xs ml-auto;
            }

            @screen lg {
                @apply text-sm;
            }
        }

        .engineered-by-arkafort {
            @apply flex flex-row mx-auto;

            @screen md {
                @apply ml-0;
            }

            & > p {
                @apply text-xs text-white;

                @screen lg {
                    @apply text-sm;
                }
            }

            & > img {
                @apply ml-4 h-4;
            }
        }
    }
}
</style>
