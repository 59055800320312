<template>
    <div class="field">
        <div v-for="(v, index) in $v.objects.$each.$iter" class="object-container">
            <div class="object-heading">
                <p class="object-title">Object ID {{parseInt(index)+1}}</p>
                <button class="object-delete" @click="deleteObject(index)" v-if="index >= 1" :disabled="disabled">Delete</button>

                <button @click="objects[index].open = !objects[index].open" :disabled="disabled">
                    <font-awesome-icon v-if="!objects[index].open" :icon="['far', 'chevron-down']"/>
                    <font-awesome-icon v-else-if="objects[index].open" :icon="['far', 'chevron-up']"/>
                </button>
            </div>

            <div class="fields-container" v-if="objects[index].open">
                <div class="field">
                    <p class="field-label">{{$t('forms.title')}}</p>

                    <input class="input" type="text" v-on:blur="answerQuestion" :class="{error: v.title.$error}" :placeholder="$t('forms.title')" v-model="v.title.$model" :disabled="disabled">

                    <div v-if="v.title.$error" class="form-errors">
                        <p v-if="!v.title.required">{{$t('forms.field_required')}}</p>
                    </div>
                </div>

                <div class="field">
                    <p class="field-label">{{$t('forms.subject')}}</p>

                    <input class="input" type="text" v-on:blur="answerQuestion" :class="{error: v.subject.$error}" :placeholder="$t('forms.subject')" v-model="v.subject.$model" :disabled="disabled">

                    <div v-if="v.subject.$error" class="form-errors">
                        <p v-if="!v.subject.required">{{$t('forms.field_required')}}</p>
                    </div>
                </div>

                <div class="field">
                    <p class="field-label">{{$t('forms.dimensions')}}</p>

                    <input class="input" type="text" v-on:blur="answerQuestion" :class="{error: v.dimensions.$error}" :placeholder="$t('forms.dimensions')" v-model="v.dimensions.$model" :disabled="disabled">

                    <div v-if="v.dimensions.$error" class="form-errors">
                        <p v-if="!v.dimensions.required">{{$t('forms.field_required')}}</p>
                    </div>
                </div>

                <div class="field">
                    <p class="field-label">{{$t('forms.materials_and_techniques')}}</p>

                    <input class="input" type="text" v-on:blur="answerQuestion" :class="{error: v.materials_and_techniques.$error}" :placeholder="$t('forms.materials_and_techniques')"
                           v-model="v.materials_and_techniques.$model" :disabled="disabled">

                    <div v-if="v.materials_and_techniques.$error" class="form-errors">
                        <p v-if="!v.materials_and_techniques.required">{{$t('forms.field_required')}}</p>
                    </div>
                </div>

                <div class="field">
                    <p class="field-label">{{$t('forms.inscriptions_or_markings')}}</p>

                    <input class="input" type="text" v-on:blur="answerQuestion" :class="{error: v.inscriptions_or_markings.$error}" :placeholder="$t('forms.inscriptions_or_markings')"
                           v-model="v.inscriptions_or_markings.$model" :disabled="disabled">

                    <div v-if="v.inscriptions_or_markings.$error" class="form-errors">
                        <p v-if="!v.inscriptions_or_markings.required">{{$t('forms.field_required')}}</p>
                    </div>
                </div>

                <div class="field">
                    <p class="field-label">{{$t('forms.date_or_period')}}</p>

                    <input class="input" type="text" v-on:blur="answerQuestion" :class="{error: v.date_or_period.$error}" :placeholder="$t('forms.date_or_period')"
                           v-model="v.date_or_period.$model" :disabled="disabled">

                    <div v-if="v.date_or_period.$error" class="form-errors">
                        <p v-if="!v.date_or_period.required">{{$t('forms.field_required')}}</p>
                    </div>
                </div>

                <div class="field">
                    <p class="field-label">{{$t('forms.maker')}}</p>

                    <input class="input" type="text" v-on:blur="answerQuestion" :class="{error: v.maker.$error}" :placeholder="$t('forms.maker')"
                           v-model="v.maker.$model" :disabled="disabled">

                    <div v-if="v.maker.$error" class="form-errors">
                        <p v-if="!v.maker.required">{{$t('forms.field_required')}}</p>
                    </div>
                </div>

                <div class="field">
                    <p class="field-label">{{$t('forms.existing_inventory_accession_number')}}</p>

                    <input class="input" type="text" v-on:blur="answerQuestion" :class="{error: v.accession_number.$error}" :placeholder="$t('forms.existing_inventory_accession_number')"
                           v-model="v.accession_number.$model" :disabled="disabled">

                    <div v-if="v.accession_number.$error" class="form-errors">
                        <p v-if="!v.accession_number.required">{{$t('forms.field_required')}}</p>
                    </div>
                </div>

                <div class="attachment-container field">
                    <p class="field-label">{{$t('forms.upload_images_of_item')}}</p>

                    <div v-if="!v.images.$model.length" class="upload-button">
                        <input type="file" name="file" :id="`file-images-${index}`" class="inputfile" @change="handleFileChange($event, objects[index], 'image')" :disabled="disabled"/>
                        <label :for="`file-images-${index}`" class="button --outline --grey --small">Choose File/s</label>
                    </div>

                    <div v-else class="multiple-upload-container">
                        <div v-for="(image, index) in v.images.$model" class="multiple-upload-wrapper">
                            <div class="file-upload">
                                <p>{{image.name}}</p>
                                <font-awesome-icon v-if="!disabled" :icon="['fal', 'times']" @click="removeFile(image.id, 'image', v.$model, index)"/>
                                <font-awesome-icon v-else :icon="['fal', 'download']" @click="downloadFile(image.id, image.name)"/>
                            </div>
                        </div>

                        <div class="upload-button" v-if="!disabled">
                            <input type="file" name="file" :id="`file-images-${index}`" class="inputfile" @change="handleFileChange($event, v.$model, 'image')" :disabled="disabled"/>
                            <label :for="`file-images-${index}`" class="button --outline --grey --small">
                                <font-awesome-icon :icon="['fal', 'plus']"/>
                            </label>
                        </div>
                    </div>

                    <div v-if="v.images.$error" class="form-errors">
                        <p v-if="!v.images.required">{{$t('forms.field_required')}}</p>
                    </div>
                </div>

                <div class="attachment-container field">
                    <p class="field-label">{{$t('forms.upload_proof_of_purchase')}}</p>

                    <div v-if="!v.proof_of_purchase.$model" class="upload-button">
                        <input type="file" name="file" :id="`file-purchase-${index}`" class="inputfile" @change="handleFileChange($event, v.$model, 'proof_of_purchase')" :disabled="disabled"/>
                        <label :for="`file-purchase-${index}`" class="button --outline --grey --small">Choose File</label>
                    </div>

                    <div v-else class="multiple-upload-container">
                        <div class="multiple-upload-wrapper">
                            <div class="file-upload">
                                <p>{{v.proof_of_purchase.$model.name}}</p>
                                <font-awesome-icon v-if="!disabled" :icon="['fal', 'times']" @click="removeFile(v.proof_of_purchase.$model.id, 'proof_of_purchase', v.$model)"/>
                                <font-awesome-icon v-else :icon="['fal', 'download']" @click="downloadFile(v.proof_of_purchase.$model.id, v.proof_of_purchase.$model.name)"/>
                            </div>
                        </div>
                    </div>

                    <div v-if="v.proof_of_purchase.$error" class="form-errors">
                        <p v-if="!v.proof_of_purchase.required">{{$t('forms.field_required')}}</p>
                    </div>
                </div>

                <div class="attachment-container field">
                    <p class="field-label">{{$t('forms.upload_proof_of_provenance')}}</p>

                    <div v-if="!v.proof_of_provenance.$model" class="upload-button">
                        <input type="file" name="file" :id="`file-provenance-${index}`" class="inputfile" @change="handleFileChange($event, v.$model, 'proof_of_provenance')" :disabled="disabled"/>
                        <label :for="`file-provenance-${index}`" class="button --outline --grey --small">Choose File</label>
                    </div>

                    <div v-else class="multiple-upload-container">
                        <div class="multiple-upload-wrapper">
                            <div class="file-upload">
                                <p>{{v.proof_of_provenance.$model.name}}</p>
                                <font-awesome-icon v-if="!disabled" :icon="['fal', 'times']" @click="removeFile(v.proof_of_provenance.$model.id, 'proof_of_provenance', v.$model)"/>
                                <font-awesome-icon v-else :icon="['fal', 'download']" @click="downloadFile(v.proof_of_provenance.$model.id, v.proof_of_provenance.$model.name)"/>
                            </div>
                        </div>
                    </div>

                    <div v-if="v.proof_of_provenance.$error" class="form-errors">
                        <p v-if="!v.proof_of_provenance.required">{{$t('forms.field_required')}}</p>
                    </div>
                </div>
            </div>
        </div>

        <Button className="--primary --outline --wide" class="add-object" v-if="objects.length > 0 && !disabled" :onclick="addObject">{{$t('forms.add_another_object_id')}}</Button>
    </div>
</template>

<script>
    import {required} from "vuelidate/lib/validators";
    import Button from "@/components/Button";

    export default {
        name: "Object",
        components: {Button},
        props: {
            field: {
                type: Object,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                objects: [
                    {
                        open: true,
                        title: null,
                        subject: null,
                        dimensions: null,
                        materials_and_techniques: null,
                        inscriptions_or_markings: null,
                        date_or_period: null,
                        maker: null,
                        accession_number: null,
                        images: [],
                        proof_of_purchase: null,
                        proof_of_provenance: null,
                    }
                ],
                attachment: {
                    id: null,
                    name: null
                },
                is_answering_question: false
            }
        },
        validations: {
            objects: {
                $each: {
                    title: {required},
                    subject: {required},
                    dimensions: {required},
                    materials_and_techniques: {required},
                    inscriptions_or_markings: {required},
                    date_or_period: {required},
                    maker: {required},
                    accession_number: {required},
                    images: {required},
                    proof_of_purchase: {required},
                    proof_of_provenance: {required},
                }
            }
        },
        methods: {
            checkFieldValid(){
                this.$v.$touch();
                return !this.$v.$anyError;
            },
            addObject() {
                let object = {
                    open: false,
                    title: null,
                    subject: null,
                    dimensions: null,
                    materials_and_techniques: null,
                    inscriptions_or_markings: null,
                    date_or_period: null,
                    maker: null,
                    accession_number: null,
                    images: [],
                    proof_of_purchase: null,
                    proof_of_provenance: null,
                };

                this.objects.push(object);
            },
            deleteObject(index) {
                this.objects.splice(index, 1);
                this.answerQuestion();
            },
            uploadFile() {
                this.is_uploading_file = true;

                let formData = new FormData();
                formData.append('name', this.attachment.name);
                formData.append('document', this.attachment);

                return this.$axios.post(`/answers/${this.field.id}/documents`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(async ({data}) => {
                        this.attachment.id = data.data.id;
                        this.is_uploading_file = false;
                    })
                    .catch(e => {
                        this.is_uploading_file = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$t('forms.error_upload_file'),
                            type: 'error',
                        });
                    })
            },
            async handleFileChange(e, object, type) {
                this.attachment = e.target.files[0];

                if(this.attachment.size > 15728640) {
                    this.attachment = {
                        id: null,
                        name: null
                    };

                    this.$notify({
                        title: this.$t('error'),
                        text: 'File size is too big',
                        type: 'error',
                    });
                } else {
                    await this.uploadFile();

                    let file = {
                        id: this.attachment.id,
                        name: this.attachment.name,
                    };

                    if(type === 'image')
                        object.images.push(file);
                    else
                        object[type] = file;

                    await this.answerQuestion();

                    this.attachment = {
                        id: null,
                        name: null
                    }
                }
            },
            async removeFile(id, type, object, index = 0) {
                this.$axios.delete(`answers/${this.field.id}/documents/${id}`)
                    .then(({data}) => {
                        if(type === 'image')
                            object.images.splice(index , 1);
                        else
                            object[type] = null;

                        this.is_removing_file = false;
                    })
                    .catch(e => {
                        this.is_removing_file = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$t('forms.error_remove_file'),
                            type: 'error',
                        });
                    });

                this.answerQuestion();
            },
            downloadFile(id, name) {
                this.$axios.get(`documents/${id}/download`, {
                    responseType: 'arraybuffer',
                })
                    .then(({data}) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', name);
                        document.body.appendChild(link);
                        link.click();
                        this.is_downloading_file = false;
                    })
                    .catch(e => {
                        this.is_downloading_file = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$t('forms.error_download_file'),
                            type: 'error',
                        });
                    });
            },
            answerQuestion() {
                if (this.disabled) return;

                this.is_answering_question = true;

                this.$axios.patch(`/applications/${this.$route.query.application}/questions/${this.field.id}/answer`, {value: this.objects})
                    .then(({data}) => {
                        this.is_answering_question = false;
                    })
                    .catch(e => {
                        this.is_answering_question = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$t('forms.error_answer_question'),
                            type: 'error',
                        });
                    })
            }
        },
        mounted() {
            if(this.field.relationships.answer.data?.attributes.value)
                this.objects = this.field.relationships.answer.data.attributes.value;
        }
    }
</script>

