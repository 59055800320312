<template>
    <div class="page-container">
        <h1>{{$t('account.my_account')}}</h1>

        <div class="content-container">
            <nav class="nav-container">
                <ul>
                    <router-link tag="li" :to="{name: 'my_details'}">
                        <font-awesome-icon :icon="['far', 'user-circle']"/>
                        {{$t('account.my_details')}}
                    </router-link>
                    <router-link tag="li" :to="{name: 'my_applications'}">
                        <font-awesome-icon :icon="['far', 'scroll']"/>
                        {{$t('account.my_applications')}}
                    </router-link>
                    <!--<router-link tag="li" :to="{name: 'payment_methods'}">
                        <font-awesome-icon :icon="['far', 'credit-card']"/>
                        {{$t('account.payment_methods')}}
                    </router-link>
                    <router-link tag="li" :to="{name: 'payments_history'}">
                        <font-awesome-icon :icon="['far', 'history']"/>
                        {{$t('account.payments_history')}}
                    </router-link>!-->
                    <router-link tag="li" :to="{name: 'account_settings'}">
                        <font-awesome-icon :icon="['far', 'cog']"/>
                        {{$t('account.account_settings')}}
                    </router-link>
                </ul>
            </nav>

            <div class="router-view">
                <router-view/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
name: "MyAccount"
}
</script>

<style lang="scss" scoped>
    .page-container {
        @apply px-4 py-12;

        @screen md {
            @apply px-8;
        }

        @screen xl {
            @apply px-48 py-14;
        }

        h1 {
            @apply text-4xl font-bold text-secondary mb-12 bg-grey-lighter;

            @screen xl {
                @apply text-5xl;
            }
        }

        & > .content-container {
            @apply flex flex-col;

            @screen xl {
                @apply flex flex-row;
            }

            .nav-container {
                @apply w-full;

                @screen xl {
                    @apply w-1/4 mr-16;
                }

                @screen 2xl {
                    @apply mr-20;
                }

                ul {
                    @apply flex flex-row overflow-x-scroll;

                    @screen xl {
                        @apply flex-col overflow-x-hidden;
                    }

                    li {
                        @apply flex w-min px-4 py-3 mr-2 text-secondary cursor-pointer rounded whitespace-nowrap font-semibold;

                        @screen xl {
                            @apply mb-4 pl-8 py-4 text-lg w-full;
                        }

                        svg {
                            @apply mr-4 text-secondary my-auto;

                            @screen xl {
                                @apply text-2xl;
                            }
                        }

                        &.router-link-exact-active {
                            @apply bg-secondary text-white;

                            & > svg {
                                @apply text-white;
                            }
                        }
                    }
                }
            }

            .router-view {
                @apply mt-8 rounded-lg bg-white px-6 py-8;
                box-shadow: 0px 3px 6px #00000029;

                @screen md {
                    @apply p-8;
                }

                @screen xl {
                    @apply w-3/4 mt-0 max-w-6xl;
                }
            }
        }
    }
</style>