<template>
    <div class="field">
        <p class="field-label">{{field.attributes.name}}</p>

        <div v-if="!uploads.length" class="upload-button">
            <input type="file" name="file" :id="`file-${field.id}`" class="inputfile" @change="handleFileChange" :disabled="disabled"/>
            <label :for="`file-${field.id}`" class="button --outline --grey --small">{{$t('forms.choose_files')}}</label>
        </div>

        <div v-else class="multiple-upload-container">
            <div v-for="(upload, index) in uploads" class="multiple-upload-wrapper">
                <div class="file-upload">
                    <p>{{upload.name}}</p>
                    <font-awesome-icon v-if="!disabled" :icon="['fal', 'times']" @click="removeFile(index)"/>
                    <font-awesome-icon v-else :icon="['fal', 'download']" @click="downloadFile(index)"/>
                </div>
            </div>

            <div class="upload-button" v-if="field.attributes.multiple && !disabled">
                <input type="file" name="file" :id="`file-${field.id}`" class="inputfile" @change="handleFileChange" :disabled="disabled"/>
                <label :for="`file-${field.id}`" class="button --outline --grey --small">
                    <font-awesome-icon :icon="['fal', 'plus']"/>
                </label>
            </div>
        </div>

        <div v-if="$v.uploads.$error" class="form-errors">
            <p v-if="!$v.uploads.required">{{$t('forms.field_required')}}</p>
        </div>

    </div>
</template>

<script>
    import {required, requiredIf} from "vuelidate/lib/validators";

    export default {
        name: "FileUpload",
        props: {
            field: {
                type: Object,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
        },
        data() {
            return {
                attachment: {
                    name: null
                },
                uploads: [],
                answer_id: null,
                is_uploading_file: false,
                is_removing_file: false,
                is_downloading_file: false,
            }
        },
        validations: {
            attachment: {
                name: {required}
            },
            uploads: {
                required: requiredIf(function () {
                    return this.field.attributes.is_required
                })
            }
        },
        methods: {
            checkFieldValid(){
                this.$v.$touch();
                return !this.$v.$anyError;
            },
            handleFileChange(e) {
                this.attachment = e.target.files[0];

                if(this.attachment.size > 15728640) {
                    this.attachment = {
                        name: null
                    };

                    this.$notify({
                        title: this.$t('error'),
                        text: this.$t('forms.file_size_too_big'),
                        type: 'error',
                    });
                } else {
                    this.answerQuestion();
                }
            },
            downloadFile(index) {
                this.is_downloading_file = true;

                this.$axios.get(`documents/${this.uploads[index].id}/download`, {
                    responseType: 'arraybuffer',
                })
                    .then(({data}) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', this.uploads[index].name);
                        document.body.appendChild(link);
                        link.click();
                        this.is_downloading_file = false;
                    })
                    .catch(e => {
                        this.is_downloading_file = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$t('forms.error_download_file'),
                            type: 'error',
                        });
                    });
            },
            removeFile(index) {
                this.is_removing_file = true;

                this.$axios.delete(`answers/${this.answer_id}/documents/${this.uploads[index].id}`)
                    .then(({data}) => {
                        this.uploads.splice(index , 1);

                        this.is_removing_file = false;
                    })
                    .catch(e => {
                        this.is_removing_file = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$t('forms.error_remove_file'),
                            type: 'error',
                        });
                    });
            },
            answerQuestion() {
                this.is_uploading_file = true;

                let payload = {
                    value: 'file'
                };

                this.$axios.patch(`/applications/${this.$route.query.application}/questions/${this.field.id}/answer`, payload)
                    .then(({data}) => {
                        this.answer_id = data.data.id;
                        this.uploadFile(this.answer_id);
                        this.is_uploading_file = false;
                    })
                    .catch(e => {
                        this.is_uploading_file = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$t('forms.error_upload_file'),
                            type: 'error',
                        });
                    })
            },
            uploadFile(id) {
                this.$v.attachment.$touch();
                if (this.$v.attachment.$anyError) {
                    return;
                }

                let formData = new FormData();
                formData.append('name', this.attachment.name);
                formData.append('document', this.attachment);

                this.$axios.post(`/answers/${id}/documents`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(({data}) => {
                        this.is_uploading_file = false;
                        this.attachment.id = data.data.id;
                        this.uploads.push(this.attachment);
                    })
                    .catch(e => {
                        this.is_uploading_file = false;

                        this.$notify({
                            title: this.$t('error'),
                            text: this.$t('forms.error_upload_file'),
                            type: 'error',
                        });
                    })
            }
        },
        mounted() {
            if(this.field.relationships.answer.data?.relationships.documents.data.length)
                this.uploads = this.field.relationships.answer.data?.relationships.documents.data.map(file => ({id: file.id, name: file.attributes.name}));

            if(this.field.relationships.answer.data?.id)
                this.answer_id = this.field.relationships.answer.data.id;
        }
    }
</script>

