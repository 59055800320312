<template>
    <div class="content-container">
        <h2>{{$t('account.account_settings')}}</h2>

        <h3>{{$t('account.update_password')}}</h3>

        <Form @submit="save" :disabled="is_saving">
            <FormGroupTwo>
                <FormInputPassword v-model="$v.password.$model" identifier="password"
                                   :label="$t('account.new_password')" :disabled="is_saving" type="password"
                                   :placeholder="$t('account.new_password')"
                                   :has-error="$v.password.$error">
                    <template v-slot:errors>
                        <p v-if="!$v.password.required">
                            {{$t('validation.x_is_required', {x: $t('account.new_password')})}}
                        </p>
                    </template>
                </FormInputPassword>
            </FormGroupTwo>

            <Button type="submit" :disabled="is_saving" className="--primary" :class="{'spinner-black': is_saving}">
                <span>{{ $t('update') }}</span>
            </Button>
        </Form>
    </div>
</template>

<script>
import Form from '@/components/form/Form';
import FormGroupTwo from '@/components/form/FormGroupTwo';
import FormInputText from '@/components/form/FormInputText';
import FormInputPassword from '@/components/form/FormInputPassword';
import FormInputDateTime from '@/components/form/FormInputDateTime';
import Button from '@/components/Button';
import {required} from 'vuelidate/lib/validators';

export default {
    name: "AccountSettings",
    components: {Form, FormGroupTwo, FormInputText, FormInputPassword, FormInputDateTime, Button},
    data() {
         return {
             password: null,
             is_saving: false
         }
    },
    validations: {
        password: {required}
    },
    methods: {
        save() {
            this.$v.password.$touch();
            if (this.$v.password.$anyError || this.is_saving) return;

            this.is_saving = true;

            this.$axios.patch(`profile`, {password: this.password}).then(({data}) => {
                this.$notify({
                    text: this.$t('profile.password_updated'),
                    type: 'success',
                });

                this.is_saving = false;
            }).catch(e => {
                this.is_saving = false;

                this.$notify({
                    title: this.$t('error'),
                    text: this.$larerror(e.response.data.errors, this.$t('profile.password_error_update')),
                    type: 'error',
                });
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .content-container {
        @apply max-w-4xl flex flex-col;

        h2 {
            @apply text-3xl font-bold text-secondary mb-12;

            @screen md {
                @apply text-3xl;
            }
        }

        h3 {
            @apply text-lg font-bold text-secondary mb-12 pb-4 border-b-2 border-grey-lighter;

            @screen md {
                @apply text-xl;
            }
        }

        .form-container {

            button {
                @apply mr-auto mt-2;
            }
        }
    }
</style>