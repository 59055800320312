<template>
    <div class="menu-drop-container">
        <div class="menu-drop">
            <ul>
                <li>
                    <router-link :to="{name: 'account'}">{{$t('account.my_account')}}</router-link>
                </li>
                <li>
                    <router-link :to="{name: 'my_applications'}">{{$t('account.my_applications')}}</router-link>
                </li>
                <li>
                    <Button className="--primary --wide" :onclick="logout">{{$t('nav.logout')}}</Button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import Button from "./Button";

    export default {
        name: "menu-drop",
        components: {Button},
        methods: {
            async logout() {
                await this.$store.dispatch('logout');
                this.$router.push({name: 'login'});
            },
        }
    }
</script>

<style lang="scss">
    .menu-drop-container {
        @apply mt-10;

        .menu-drop {
            @apply block bg-white rounded-xl w-full p-2;
            box-shadow: 0px 5px 25px #00000029;

            ul {
                @apply list-none m-4;

                li {
                    @apply my-4 text-secondary text-right font-bold text-sm capitalize;

                    &:hover {
                        @apply text-secondary-darker;
                    }
                }
            }
        }
    }
</style>
